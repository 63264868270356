import React, { useState, useEffect } from "react";
import { useMedia } from "use-media";
import Layout from "../../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { VideoElement2 } from "../../../components/video";
import { OnScrollPopup } from "../hr-chatbot";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import AliceCarousel from "react-alice-carousel";
import axios from "axios";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";
import { redirectToWithUtm } from "../../../components/Header";

const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");

const BlogHeader = require("../../../assets/img/blogs/blog_header.png");
const Section1 = require("../../../assets/img/blogs/chatbot_best_1.png");
const Section2 = require("../../../assets/img/blogs/chatbot_best_2.png");
const Section3 = require("../../../assets/img/blogs/chatbot_best_3.jpeg");
const Section4 = require("../../../assets/img/blogs/Blog_Chatbot Best Practices_1.jpg");
const Section5 = require("../../../assets/img/blogs/Blog_Chatbot Best Practices_2.jpg");
const Section6 = require("../../../assets/img/blogs/Blog_Chatbot Best Practices_3.jpg");
const Section7 = require("../../../assets/img/blogs/Blog_Chatbot Best Practices_4.jpg");
const Section8 = require("../../../assets/img/blogs/Blog_Chatbot Best Practices_5.jpg");
const Section9 = require("../../../assets/img/blogs/Blog_Chatbot Best Practices_6.jpg");

const SectionLast = require("../../../assets/img/blogs/chatbot_best_final.gif");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Chatbot Best Practices for designing a great Conversational AI Experience "
        description="Learn some of the best practices of building great conversational ai user experience using workativ assistant "
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={BlogHeader}
        schema={ldSchema}
      
      />
      <section className="market_main_wrapper">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header ">
                            Chatbot Best Practices for designing a
                            Conversational Experience with Workativ Assistant
                          </h1>
                        </div>
                      </div>
                      <img loading="lazy" src={BlogHeader} />
                      <div className=" mt-5 market_wrapper_page">
                        <p class="font-section-normal-text line-height-2">
                          Workativ Assistant:Hey <span>&#60;</span>insert your
                          name
                          <span>&#62;</span>! I’m <span>&#60;</span>insert a
                          name that comes to your mind for the chatbot{" "}
                          <span>&#62;</span>, a chatbot that not only answers
                          your <span>&#60;</span>insert search topic
                          <span>&#62;</span> queries but also takes care of your{" "}
                          <span>&#60;</span>insert search topic
                          <span>&#62;</span> needs in a jiffy. Here are a few
                          things I can help you with to get you started:
                        </p>
                        <p class="font-section-normal-text mb-0 pb-0">
                          {" "}
                          <span>&#91;</span>Option 1
                        </p>
                        <p class="font-section-normal-text mb-0 pb-0">
                          Option 2
                        </p>
                        <p class="font-section-normal-text mb-0 pb-0">
                          Option 3<span>&#93;</span> Clickable Buttons
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          You might’ve come across this standard greeting
                          structure for a{" "}
                          <a href="https://chatbotslife.com/?gi=4c77c6891cc6">
                            chatbot
                          </a>{" "}
                          that pops out of nowhere when you visit a site for
                          reading an article or checking out a product. And you
                          might’ve been annoyed with searching for the ‘x’ icon
                          and not being able to close it. Yeah, I’ve been there.
                          And I get you.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Chatbots have become commonplace now, be it on
                          websites or on self-service portals, at least at a
                          <a href="https://www.interaction-design.org/literature/topics/skeuomorphism">
                            {" "}
                            skeuomorphic
                          </a>{" "}
                          level. Most of the chatbots that you came across
                          online might’ve interacted with you as a normal human
                          would do, turn-taking, and sounding empathetic. But it
                          wouldn’t have been able to take care of issues that
                          you reported to it as a normal human would do.
                          Instead, it would’ve redirected you to a help desk
                          agent who solved your query in real-time. But this is
                          now changing with conversational AI on the rise and
                          <a href="https://workativ.com/conversational-ai-platform">
                            {" "}
                            Workativ Assistant
                          </a>{" "}
                          is one such PaaS that’ll help you get the competitive
                          edge when it comes{" "}
                          <a href="https://workativ.com/conversational-ai-platform/blog/conversational-ai-focus-on-user-experience">
                            to both conversational AI and automation.
                          </a>
                        </p>
                      </div>

                      <NocodeWrapper />

                      <div>
                        <h2 className="font-section-sub-header-small-bold">
                          What is Workativ Assistant?
                        </h2>

                        <div class="wp-block-embed__wrapper mb-5">
                          <div
                            class="rll-youtube-player"
                            data-src="https://www.youtube.com/embed/YRhHicf-CQM"
                            data-id="YRhHicf-CQM"
                            data-query="feature=oembed"
                          >
                            <iframe
                              src="https://www.youtube.com/embed/YRhHicf-CQM?autoplay=1&amp;feature=oembed"
                              frameborder="0"
                              allowfullscreen="1"
                              style="width: 100%; height:400px"
                              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            ></iframe>
                          </div>
                        </div>
                        <p class="font-section-normal-text line-height-2">
                          If you’re a person who has no idea what Workativ
                          Assistant is or what the heck I’m blabbering about, no
                          issues. I got you.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Workativ Assistant is an AI-powered PaaS for building
                          contextual chatbots with automated workflows and
                          deploying them on your business’ Slack or Microsoft
                          Teams workspace so that you’re employees can
                          self-serve their IT/HR issues in an instant.
                        </p>
                      </div>

                      <div className="market_wrapper_page">
                        <h5 className="font-section-sub-header-small-bold">
                          Most Popular Chatbot Tutorials:
                        </h5>
                        <p class="font-section-normal-text">
                          <a href="https://chatbotslife.com/build-your-telegram-bot-scheduler-with-python-a569518dc2df">
                            1. Build a Telegram Bot Scheduler with Python
                          </a>
                        </p>
                        <p class="font-section-normal-text">
                          <a href="https://chatbotslife.com/a-conversational-ui-maturity-model-a-guide-to-take-your-bot-to-the-next-level-4552d16724a2">
                            2. A Conversational UI Maturity Model: a guide to
                            take your bot to the next level
                          </a>
                        </p>
                        <p class="font-section-normal-text">
                          <a href="https://chatbotslife.com/designing-a-chatbot-for-an-improved-customer-experience-813c88f6a32d">
                            3. Designing a chatbot for an improved customer
                            experience
                          </a>
                        </p>
                        <p class="font-section-normal-text">
                          <a href="https://chatbotslife.com/chatbot-natural-languagand-search-services-and-how-to-mash-them-up-for-a-better-user-experience-ed13e788c821">
                            {" "}
                            4. Chatbot, Natural Language Processing (NLP) and
                            Search Services and how to mash them up for a better
                            user experience
                          </a>
                        </p>
                        <p class="font-section-normal-text">
                          Now that we got that out of the way, let’s take a look
                          at what all you should do before designing a
                          conversation/dialog flow for a chatbot on Workativ
                          Assistant.
                        </p>
                      </div>

                      <div className="market_wrapper_page">
                        <h5 className="  font-section-sub-header-small-bold">
                          It’s all about the User
                        </h5>
                        <p className="font-section-normal-text">
                          Yeah, it is. Before getting your hands on Workativ
                          Assistant’s 
                          <a href="https://help.workativ.com/knowledgebase/building-a-dialog/">
                            Dialog Designer,
                          </a>{" "}
                          it is best that you do the following without getting
                          carried away:{" "}
                        </p>
                        <h6 class="font-section-sub-header-small-bold">
                          1. Start with a User Story
                        </h6>

                        <img src={Section1} />
                        <p className="font-section-sub-header-small mt-5 line-height-2">
                          As a/an <span>&#91;</span>role<span>&#93;</span> ,I
                          should be able to <span>&#91;</span>action
                          <span>&#93;</span> <span>&#40;</span>so that{" "}
                          <span>&#91;</span>benefit
                          <span>&#93;</span>
                          <span>&#41;</span>.{" "}
                        </p>

                        <p className="font-section-normal-text line-height-2">
                          To put it more into perspective, as an employee, I
                          should be able to unlock my account instantly so that
                          I can continue with my work. Get the drift?
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          This is a simple format of a user story that will help
                          you in the long run. Always starting with the user
                          story in mind will help you come up with efficient
                          dialog flows for the{" "}
                          <a href="https://chatbotslife.com/">chatbot</a> that
                          requires less input from the user{" "}
                          <a href="https://workativ.com/conversational-ai-platform/blog/conversational-ai-enterprise">
                            and makes the chatbot sound empathizing
                          </a>{" "}
                          to the user.
                        </p>
                      </div>

                      <div className="market_wrapper_page">
                        <h6 class="font-section-sub-header-small-bold">
                          2. Help desk agents are the best people to talk to
                        </h6>
                        <img loading="lazy" src={Section2} />

                        <p className="font-section-normal-text mt-5 line-height-2">
                          Your help desk agents are the ones who are on the
                          front line, attending innumerable calls from employees
                          and troubleshooting their IT issues. So it’s no rocket
                          science that help desk agents are the ones you should
                          talk to when coming up with a dialog flow for a
                          chatbot. Because they very well know how to address a
                          particular user query, be it a simple password reset
                          or an account unlock, without putting off the user.
                        </p>
                      </div>

                      <div className="market_wrapper_page">
                        <h6 class="font-section-sub-header-small-bold">
                          3. Putting it all into perspective
                        </h6>
                        <p className="font-section-normal-text line-height-2">
                          After gathering all the data there is from the help
                          desk agents, the next sensible thing for you to do
                          would be to whiteboard the dialog flows for the
                          repetitive user stories that can be automated. This
                          can be a simple file or information retrieval from a
                          central knowledge base, help desk ticket creation,
                          leave application, password reset or an account
                          unlock.
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          While whiteboarding a dialog flow for a user story,
                          three things should be kept in mind at all times:
                        </p>
                        <p className="font-section-normal-text">
                          a. Chatbot Persona
                        </p>
                        <img loading="lazy" src={Section3} />
                        <p className="font-section-normal-text mt-4 line-height-2">
                          Before designing the actual dialog flow, it’s better
                          if you decide on a tone/personality for the chatbot
                          that aligns with how your employees expect your help
                          desk agents to take care of their IT issues. You can
                          even mix a bit of humor into the chatbot’s responses
                          so that employees find the chatbot fun and amusing to
                          use. Following are some of the things that you should
                          set up for the chatbot without fail while designing a
                          dialog flow for it:
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Proper Intro:
                          </span>{" "}
                          Always set up an intro for the chatbot to greet the
                          user as soon as they initiate a conversation with it.
                          The greeting should include a hi or a hello followed
                          by the username and the chatbot letting the user know
                          that they are talking to a chatbot and then the
                          chatbot should list down a couple of options for the
                          user to get them started.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Acknowledgment:
                          </span>{" "}
                          The chatbot should acknowledge the user by restating
                          the information provided by the user so that the user
                          can confirm it.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            In case of an error:
                          </span>{" "}
                          When the{" "}
                          <a href="https://chatbotslife.com/">chatbot</a>{" "}
                          doesn’t understand what a user is asking it to do, or
                          if it fails to execute an automation corresponding to
                          the user’s IT issues, it should politely acknowledge
                          the error/shortcoming and provide the user with
                          alternate solutions like talking to a live help desk
                          agent.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Ending the conversation:
                          </span>{" "}
                          The chatbot should ask the user if their query has
                          been solved before ending the conversation. If a user
                          says their query hasn’t been solved, it should ask
                          them if it can redirect them to a live help desk agent
                          for further assistance. If the user confirms that
                          their query has been solved, the chatbot should ask
                          the user for feedback and then end the conversation
                          letting the user know that the conversation has ended
                          and if they have further queries they’ll have to
                          restart the conversation.
                        </p>

                        <p className="font-section-normal-text">
                          b. User Intent
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          You can never correctly guess what an end-user might
                          ask the chatbot. You’ll first have to come to terms
                          with this fact.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          For example, a user might ask the chatbot to book a
                          ticket to the Maldives not specifying the mode of
                          transportation, i.e. whether by flight or on a cruise
                          ship.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Even if you were to guess certain terms a user might
                          use to report her/his issue based on a user testing
                          control group, the terms won’t be an accurate
                          depiction of what the actual user might ask. That’s
                          because people in a user testing control group will
                          test the chatbot’s limits and not test it for the
                          specified user story.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          For example, you want the control group to test the
                          chatbot for the IT support ticket creation scenario.
                          Instead of asking the chatbot to create a ticket for
                          them, they might ask the chatbot about what all it can
                          do or they might ask it to retrieve a piece of
                          information for them. But you can still use the
                          information from the control group but not solely rely
                          on it.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          The best thing to do in this case would be to make a
                          copy of the workspace where the dialog is created
                          based on the user testing control group and make this
                          copy live to the actual users so that you can track
                          their interactions using conversation logs and improve
                          the dialog based on it. In cases where the user count
                          is large, instead of scrolling through piles of
                          conversation data, you can check the feedback given by
                          users at the end of conversations to fine-tune the
                          dialog.
                        </p>

                        <p className="font-section-normal-text">
                          c. Data to be extracted
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          The next thing to keep in mind is the data required
                          from the user for the chatbot to start an automated
                          workflow. It is best to keep questions to a bare
                          minimum to the user in order to avoid frustrated
                          users. Therefore it’s important to ask for the
                          required data in an efficient manner.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Now that we know how to properly whiteboard a dialog
                          flow for a user story based on data from help desk
                          agents, our next step would be to actually go on and
                          set up the dialog flow using Workativ Assistant’s
                          Dialog Designer.
                        </p>
                      </div>

                      {/* Section 5 */}
                      <div className="market_wrapper_page" id="section5">
                        <h5 className="font-section-sub-header-small-bold">
                          Setting up a Dialog Flow using the Dialog Designer
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Using Workativ Assistant’s intuitive Dialog Designer,
                          setting up dialog flows for various user stories
                          becomes a breeze. Just a click here and a text there
                          and you’re set.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Following are the steps for properly setting up a
                          dialog flow on Workativ Assistant:
                        </p>
                        <h5 class="font-section-sub-header-small">
                          1. Why you should almost always start with the
                          automation
                        </h5>
                        <p className="font-section-normal-text line-height-2">
                          Before setting up a dialog flow, you should almost
                          always set up the automation for it. Why, you ask?
                          Because then only will you get to know the inputs that
                          are required by the automation from the user for a
                          particular user story.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          For instance, if you’re setting up a dialog flow for
                          unlocking a user’s account using Active Directory, you
                          can know the inputs required from the user’s side by
                          first setting up an automation for it as follows.
                        </p>
                        <img loading="lazy" src={Section4} />
                        <h5 class="font-section-sub-header-small mt-4">
                          2. Getting started with dialog creation based on a
                          user story
                        </h5>
                        <p className="font-section-normal-text mb-1 line-height-2">
                          First, you’ll have to create a chatbot on Workativ
                          Assistant by giving a name for it.
                        </p>
                        <img loading="lazy" src={Section5} />
                        <p className="font-section-normal-text mb-1 mt-3 line-height-2">
                          Next, the logical thing to do would be to start with
                          setting up a dialog for greeting the user. Thankfully,
                          Workativ Assistant takes care of this for you. It
                          creates a “Welcome” dialog which you can customize
                          according to your needs using the various features
                          available on the Dialog Designer.
                        </p>
                        <p className="font-section-normal-text  mb-1 line-height-2">
                          Let’s take the unlock account scenario and create a
                          dialog for it. In the “Welcome” dialog, we can set up
                          a question and give answers as options via clickable
                          buttons for the user to choose using the “Options”
                          feature. We’ll have to assign values to the options
                          that are basically Intents but more on that later.
                        </p>
                        <p className="font-section-normal-text mb-1 line-height-2">
                          After customizing the “Welcome” dialog, we’ll have to
                          create a new dialog using the “Create New” option on
                          the Dialog Designer homepage of the chatbot that you
                          created.
                        </p>
                        <img loading="lazy" src={Section6} />
                        <p className="font-section-normal-text mt-4 line-height-2">
                          Then, you’ll be prompted with the “Recognize Dialog”
                          pop up where you’ll have to specify the Intent for
                          which this dialog will get executed by the chatbot.
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          Intents are nothing but examples that’ll you’ll have
                          to specify under an umbrella term that can be used by
                          the chatbot to identify the user’s intention for
                          initiating a conversation with it.
                        </p>
                        <p className="font-section-normal-text mt-4 line-height-2">
                          After specifying an Intent for the dialog, we’ll have
                          to set up the responses for the chatbot. In the case
                          of unlocking an account using Active Directory, the
                          chatbot will have to ask the user for their Email ID
                          and then pass this information to the automation that
                          we set up earlier in order to solve the user’s issue
                          real-time. For this, we can use the “Ask a Question”
                          feature which asks the user a question and stores the
                          user’s answer inside the specified variable.
                        </p>
                        <SliderBlog />
                        <p className="font-section-normal-text mt-4 line-height-2">
                          The same can be done using an Entity and the “Get User
                          Details” feature but we’ll explore them in detail in
                          another article.
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          Next, we’ll have to let the user know that it’ll take
                          some time for the chatbot to unlock their account and
                          that they’ll have to wait. For that, we can use the
                          “Send a Message” feature. By default, the “Send a
                          Message” feature will display messages that you
                          specify sequentially. You can even choose to display
                          the messages randomly to break the monotony by
                          selecting the “Display Randomly” option.
                        </p>
                        <figure>
                          <iframe
                            src="https://cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.icloud.com%2Fkeynote%2F0o6ZWDfdSVdhjaBG_o_1HirJA%3Fembed%3Dtrue&amp;display_name=Apple&amp;url=https%3A%2F%2Fwww.icloud.com%2Fkeynote%2F0o6ZWDfdSVdhjaBG_o_1HirJA&amp;image=https%3A%2F%2Fp67-iworkthumbnailws.icloud.com%2Fiw%2Fws%2Fthumbnail%3Fshort_token%3D0o6ZWDfdSVdhjaBG_o_1HirJA%26maxWidth%3D2048%26forceLandscape%3Dtrue%26cropAspectRatioHint%3D2%26socialMetaTagsForApp%3Dkeynote&amp;key=a19fcc184b9711e1b4764040d3dc5c07&amp;type=text%2Fhtml&amp;schema=icloud"
                            allowfullscreen=""
                            width="600"
                            height="386"
                          ></iframe>
                        </figure>{" "}
                        <p className="font-section-normal-text mt-4 line-height-2">
                          Our next step would be to map this dialog to the
                          automation that we created earlier for unlocking an
                          account using Active Directory and pass the user’s
                          email information to the automation. We can do this
                          using the “Call an Automation” feature and the
                          “Variable Picker.” You can choose to let Success and
                          Failure sub-dialogs be created automatically or you
                          can choose to manually handle this by selecting the
                          relevant options under the “Create Sub-Dialogs for” on
                          the “Call an Automation” pop up.
                        </p>
                        <figure>
                          <iframe
                            src="https://cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.icloud.com%2Fkeynote%2F0Wro2WXpFjhmpBquOB5wS2U8w%3Fembed%3Dtrue&amp;display_name=Apple&amp;url=https%3A%2F%2Fwww.icloud.com%2Fkeynote%2F0Wro2WXpFjhmpBquOB5wS2U8w&amp;image=https%3A%2F%2Fp67-iworkthumbnailws.icloud.com%2Fiw%2Fws%2Fthumbnail%3Fshort_token%3D0Wro2WXpFjhmpBquOB5wS2U8w%26maxWidth%3D2048%26forceLandscape%3Dtrue%26cropAspectRatioHint%3D2%26socialMetaTagsForApp%3Dkeynote&amp;key=a19fcc184b9711e1b4764040d3dc5c07&amp;type=text%2Fhtml&amp;schema=icloud"
                            allowfullscreen=""
                            width="600"
                            height="386"
                          ></iframe>
                        </figure>{" "}
                        <p className="font-section-normal-text mt-4 line-height-2">
                          You’ll then have to create responses for the “Success”
                          and “Failure” sub-dialogs. For the “Success” dialog,
                          you can ask the user if they want to continue
                          unlocking another account by making use of the
                          “Options” feature and providing easy clickable options
                          like “Yes”and “No.” You can use the “Jump” feature to
                          make the chatbot jump from the “Yes” sub-dialog jump
                          to the start of the entire unlock account dialog if
                          the user chooses “Yes.” For the “No” and “Failure”
                          sub-dialogs, you can use the “Exit” feature to let the
                          user know that something is wrong and that they’ll
                          have to contact their IT Admin if the issue persists.
                          You can even choose to ask the user for their feedback
                          by turning ON the feedback button on the “Exit” pop
                          up.
                        </p>
                        <figure>
                          <iframe
                            src="https://cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.icloud.com%2Fkeynote%2F0hWrnc-VFr2oTfyftaKK6G1Ww%3Fembed%3Dtrue&amp;display_name=Apple&amp;url=https%3A%2F%2Fwww.icloud.com%2Fkeynote%2F0hWrnc-VFr2oTfyftaKK6G1Ww&amp;image=https%3A%2F%2Fp67-iworkthumbnailws.icloud.com%2Fiw%2Fws%2Fthumbnail%3Fshort_token%3D0hWrnc-VFr2oTfyftaKK6G1Ww%26maxWidth%3D2048%26forceLandscape%3Dtrue%26cropAspectRatioHint%3D2%26socialMetaTagsForApp%3Dkeynote&amp;key=a19fcc184b9711e1b4764040d3dc5c07&amp;type=text%2Fhtml&amp;schema=icloud"
                            allowfullscreen=""
                            width="600"
                            height="386"
                          ></iframe>
                        </figure>
                        <p className="font-section-normal-text mt-4 line-height-2">
                          After setting up the entire dialog, you’ll have to
                          deploy the dialog to update all the changes that you
                          did to the dialog by clicking on the “Deploy” button.
                        </p>
                        <figure>
                          <iframe
                            src="https://cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.icloud.com%2Fkeynote%2F0iweFgsioFLp6SJejRZnyQ6RA%3Fembed%3Dtrue&amp;display_name=Apple&amp;url=https%3A%2F%2Fwww.icloud.com%2Fkeynote%2F0iweFgsioFLp6SJejRZnyQ6RA&amp;image=https%3A%2F%2Fp67-iworkthumbnailws.icloud.com%2Fiw%2Fws%2Fthumbnail%3Fshort_token%3D0iweFgsioFLp6SJejRZnyQ6RA%26maxWidth%3D2048%26forceLandscape%3Dtrue%26cropAspectRatioHint%3D2%26socialMetaTagsForApp%3Dkeynote&amp;key=a19fcc184b9711e1b4764040d3dc5c07&amp;type=text%2Fhtml&amp;schema=icloud"
                            allowfullscreen=""
                            width="600"
                            height="386"
                          ></iframe>
                        </figure>
                        <p className="font-section-normal-text mt-4 line-height-2">
                          After deploying the dialog, you can test the dialog
                          using the “Try Me” option.
                        </p>
                        <img loading="lazy" src={Section8} />
                        <div className="blog_gif">
                          <img
                            loading="lazy"
                            src={SectionLast}
                            className="mt-3"
                          />
                        </div>
                        <p className="font-section-normal-text mt-4 line-height-2">
                          And that’s how you can easily create a dialog for a
                          user story using Workativ Assistant’s Dialog Designer.
                          You can then deploy Workativ Assistant on your
                          business’ Slack or Microsoft Teams workspace and get
                          the load off your help desk agents once and for all.
                          Pretty neat, huh?
                        </p>
                        <p className="font-section-normal-text-medium line-height-2">
                          Not yet tried Workativ Assistant? Then you’re missing
                          out on a lot, I can tell you that. Get in touch with
                          us at sales@workativ.com and we’d be more than happy
                          to give you a demo of our product.
                        </p>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot-for-employee-service">
                                Conversational AI Chatbot for employee service
                                automation
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot">
                                {" "}
                                What Is Conversational AI? A Guide to
                                Conversational AI Chatbots
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/top-tools-to-build-conversational-ai-chatbot">
                                Conversational AI Tools—Top 6 Tools To Build
                                Conversational AI Chatbot
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {isSmall ? null : <OnScrollPopup />} */}

            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>No Code - Free Conversational AI Chatbot </h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="Conversational AI chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};

function SliderBlog() {
  return (
    <div className="blog-slider ">
      {/* <AliceCarousel autoPlay autoPlayInterval="3000">
      <img loading = "lazy" src={Section2} className="sliderimg-blog" alt=""/>
      <img loading = "lazy" src={Section3} className="sliderimg-blog" alt=""/>
      <img loading = "lazy" src={Section4} className="sliderimg-blog" alt=""/>
      <img loading = "lazy" src={Section5} className="sliderimg-blog" alt=""/>
    </AliceCarousel> */}

      <div
        id="carouselExampleControls"
        class="carousel slide"
        data-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img class="d-block w-100" src={Section6} alt="First slide" />
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src={Section6} alt="Second slide" />
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src={Section6} alt="Third slide" />
          </div>
        </div>
        <a
          class="carousel-control-prev"
          href="#carouselExampleControls"
          role="button"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#carouselExampleControls"
          role="button"
          data-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
  );
}
